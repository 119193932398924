/*
*@Copyright:layui.com
*/

/*base*/
@charset "UTF-8";
* {
  margin: 0;
  padding: 0;
  letter-spacing: 0.7px;
  box-sizing: border-box;
}
body {
  font: 12px/1.5 PingFangSC-Regular, Tahoma, "Microsoft Yahei", sans-serif;
}

input::-webkit-input-placeholder {
  color: #989898;
}

input::-moz-input-placeholder {
  color: #989898;
}

input::-ms-input-placeholder {
  color: #989898;
}

button {
  cursor: pointer;
  transition: opacity 0.4s;
  &:hover {
    opacity: 0.86;
  }
}
a{cursor: pointer!important;}

.layui-unselect.layui-tab-bar{display: none !important; }


.fl {
  float: left;
}

.fr {
  float: right;
}

.clearfix {
  clear: both;
  zoom: 1;
}

.inline-block {
  display: inline-block;
}

.w100 {
  width: 100%;
}

.h100 {
  height: 100%;
}

.min-wrap {
  min-width: 1216px;
  overflow: hidden;
}

.wrap {
  width: 1216px;
  padding: 0 8px;
  margin: 0 auto;
}

.col-tit {
  color: #191f25;
}

.col-green {
  color: #1cdcd5;
}

.col-orange {
  color: #ff814f;
}

.col-white {
  color: #ffffff;
}

.col-gray {
  color: #a6a6a6;
}

.ls1 {
  letter-spacing: 1px;
}

.ls12 {
  letter-spacing: 1.2px;
}

.ls15 {
  letter-spacing: 1.5px;
}

.padding0 {
  padding: 0;
}

.tl {
  text-align: left;
}

.tc {
  text-align: center;
}

.tr {
  text-align: right;
}

.fb {
  font-weight: bold;
}

.f14 {
  font-size: 14px;
}

.f16 {
  font-size: 16px;
}

.f18 {
  font-size: 18px;
}

.f20 {
  font-size: 20px;
}

.f22 {
  font-size: 22px;
}

.f26 {
  font-size: 26px;
}

.f28 {
  font-size: 28px;
}

.f30 {
  font-size: 30px;
}

.lh28 {
  line-height: 28px;
}
.relitive{
    position: relative;
}
.ellipsis-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ellipsis-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.clear-fix {
  *zoom: 1;
}

.clear-fix:before,
.clear-fix:after {
  display: table;
  line-height: 0;
  content: "";
}

.clear-fix:after {
  clear: both;
}

.block {
  display: block;
}

a {
  &:hover {
    opacity: 0.88;
  }
}

.f12 {
  font-size: 12px;
}
/* 分页 */
.layui-laypage {
  a {
    &:hover {
      color: #1cdcd5;
    }
  }
}
.layui-laypage-em {
  background-color: #1cdcd5 !important;
}
//查看详情按钮公共样式
.btn-detail {
  background-color: #ff814f;
  border-radius: 22px;
  width: 112px;
  height: 44px;
  text-align: center;
  color: #fff;
  border: none;
}

/*header*/

.header_box {
  .tops {
    background-color: #fafafa;
    height: 38px;
    line-height: 38px;
    color: #8d8d8d;
    .rlinks {
      a {
        color: #8d8d8d;
        &:hover {
          color: #333;
        }
      }
      .r-one:not(:last-child) {
        position: relative;
        padding: 0 8px;
        &:after {
          content: "";
          position: absolute;
          display: inline-block;
          width: 1px;
          height: 10px;
          background-color: #d8d8d8;
          right: 0px;
          top: 3px;
        }
      }
    }
  }
  .header {
    position: relative;
    height: 80px;
    line-height: 80px;
    //    overflow: hidden;
    .hl {
      height: 100%;
      font-size: 14px;
      line-height: 74px;
      .logo-img {
        width: 142px;
        height: auto;
        margin-right: 8px;
        &.logo-sximg{
            width: 220px !important;    
        }
        
      }
      .loc {
        color: #a6a6a6;
        vertical-align: 2px;
        cursor: pointer;
      }
      .down-icon {
        margin-top: 2px;
        width: 10px;
        height: auto;
      }
      .city-wrap{
          display: none;
          position: absolute;
          width: 180px;
          min-height: 50px;
          height: auto;
          padding: 20px 10px 0 !important;
          background-color: #fff;
          border:1px solid #EFEFEF;
          border-radius: 2px;
          right: -140px;
          top: 64px;
          z-index: 100;
          ul{
              display: inline-block;
              clear: both;
              zoom: 1;
              width: 100%;
              li{
                  float: left;
                  width: 33.3%;
                  text-align: center;
                  line-height: 37px;
                  a{
                      display: inline-block;
                      width: 100%;
                      height: 100%;
                      line-height: 37px;
                      color: #8B8B8B;
                      &.active,&:hover{
                          color:#1CDCD5;
                      }
                      
                  }
                  &.qg{
                      width: 100% ;
                      padding-left: 12px;
                      text-align: left;
                  }
              }
          }
      }
    }
    .hc {
      height: 100%;
      .layui-nav {
        background-color: #fff;
        padding:0 8px;
        .layui-nav-bar {
          display: none;
        }
        .layui-nav-child {
          top: 80px;
        }
        .layui-nav-item {
          line-height: inherit !important;
          a {
            color: #616161;
            font-size: 16px;
            padding: 0 11px;
            text-align: left;
            &:hover {
              color: #1cdcd5;
            }
          }
          &:after {
            display: none !important;
          }
          &.layui-this {
            a {
              color: #1cdcd5 !important;
              background-color: #fff !important;
            }
          }
        }
      }
    }
    .hr {
      height: 100%;
      .phone-icon {
        width: auto;
        height: 19px;
        vertical-align: -4px;
        margin-right: 4px;
      }
      .phone-txt {
        color: #3c3939;
        font-size: 16px;
        font-weight: 800;
        letter-spacing: -0.5px !important;
      }
    }
  }
  .layui-nav .layui-nav-child dd.layui-this a, .layui-nav-child dd.layui-this{
      background-color: #f3f3f3 !important;
  }
}

/*end-header*/

/**nodata*/
.nodata{
    border: 0px !important;
    background-color: transparent !important;
    width: 100% !important;
    text-align: center !important;
    color: #8b8b8b !important;
    font-size: 16px !important;
    padding: 80px 0 !important;
    box-sizing: border-box !important;
}  
/**nodata end*/

/**slide单张 start**/

.oneimg {
  width: 100%;
  height: 560px;
  overflow: hidden;
  .img-wh {
    width: 100%;
    height: 560px;
  }
}

/**slide单张 end**/

/**个性化课程设置start*/

.set-wrap {
  padding-bottom: 46px;
  .set-list {
    ul {
      width: 100%;
      clear: both;
      display: inline-block;
      li {
        float: left;
        text-align: center;
        overflow: hidden;
        width: 376px;
        height: 368px;
        cursor: pointer;
        margin-bottom: 26px;
        border: 1px solid #e8e8e8;
          transition: all 0.3s;
        &:hover {
            transform: translateY(-8px);
            box-shadow: 0px 10px 44px -11px rgba(0, 0, 0, 0.2);
        }
        .list-con {
          border-top: none;
          height: 160px;
        }
        .setimg-wrap {
          width: 100%;
          img {
            width: 100%;
            height: 200px;
            box-shadow: 0px 10px 30px -8px rgba(0, 0, 0, 0.1);
          }
        }
        .setit {
          padding-top: 20px;
          line-height: 28px;
        }
        .setcon {
          color: #5e6061;
          line-height: 22px;
          padding-top: 6px;
        }
        .setbtn {
          padding-top: 15px;
          button {
            font-size: 16px;
            width: 144px;
            height: 44px;
            transition: opacity 0.3s;
            &:hover {
              opacity: 0.86;
            }
          }
        }
        &:not(:nth-child(3n)) {
          margin-right: 34px;
        }
      }
    }
  }
}

/**个性化课程设置end*/

/**标题start**/

//标题
.block-tit {
  padding-top: 50px;
  //  首页
  &.tit1 {
    padding-bottom: 86px;
    img {
      width: 472px;
      height: auto;
    }
  }
  &.tit2 {
    padding-bottom: 83px;
    img {
      width: 378px;
      height: auto;
    }
  }
  &.tit3 {
    padding-bottom: 32px;
    img {
      width: 510px;
      height: auto;
    }
  }
  &.tit4 {
    padding-bottom: 24px;
    img {
      width: 340px;
      height: auto;
    }
  }
  &.tit5 {
    padding-bottom: 24px;
    img {
      width: 535px;
      height: auto;
    }
  }
  &.tit7 {
    padding-top: 64px;
    padding-bottom: 70px;
    img {
      width: 535px;
      height: auto;
    }
  }
  &.tit10 {
    padding-top: 64px;
    //  padding-bottom: 24px;
    img {
      width: 608px;
      height: auto;
    }
  }
  &.tit11 {
    padding-top: 64px;
    padding-bottom: 76px;
    img {
      width: 720px;
      height: auto;
    }
  }
  &.tit12 {
    padding-top: 62px;
    padding-bottom: 90px;
    img {
      width: 580px;
      height: auto;
    }
  }
  &.tit13 {
    padding-top: 64px;
    padding-bottom: 90px;
    img {
      width: 580px;
      height: auto;
    }
  }
}

.datas-wrap {
  .block-sub-tit,
  .subtitl0 {
    padding-bottom: 50px !important;
  }
}
/**标题end**/

/**关于秦学公共部分**/

/**tab nav start**/

.tabnav-wrap {
  padding: 60px 0 62px;
  text-align: center;
  width: 100%;
  ul {
    clear: both;
    zoom: 1;
    display: inline-block;
    width: 100%;
    margin: 0 auto;
    li {
      float: left;
      a {
        display: inline-block;
        width: 294px;
        height: 64px;
        line-height: 64px;
        text-align: center;
        font-size: 22px;
        letter-spacing: 1px;
        background-color: #f0f3f6;
        transition: all 0.2s;
        &:hover,
        &.active {
          color: #fff;
          background-color: #1cdcd5;
        }
      }
      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }
}

/**tab nav end**/

/**关于秦学公共部分**/

/*footer*/

.footer {
  background: #222222;
  padding: 40px 0;
  height: 410px;
  .col-6f {
    color: #6f6f6f;
  }
  .links-wrap {
    padding-top: 62px;
  }
  .footer-bot {
    padding-top: 20px;
    p {
      padding-bottom: 14px;
    }
  }
  .scan {
    padding-top: 21px;
    padding-bottom: 10px;
  }
  .footer-top {
    .rtop {
      padding-left: 10px;
      .txt {
        color: #999999;
      }
    }
    .limg {
      position: relative;
      background-color: #393939;
      width: 56px;
      height: 56px;
      border-radius: 56px;
      text-align: center;
      &:after {
        content: "";
        position: absolute;
        background: url(../images/ficon.png) no-repeat;
        background-size: 322% 100%;
      }
      &.limg1 {
        &:after {
          top: (56px-20px)/2;
          left: (56px-30px)/2;
          width: 28px;
          height: 20px;
          background-position: 0 0;
        }
      }
      &.limg2 {
        &:after {
          top: (56px-25px)/2;
          left: (56px-32px)/2;
          width: 28px;
          height: 26px;
          background-size: 402% 100% !important;
          background-position: -44px 0;
        }
      }
      &.limg3 {
        &:after {
          top: (56px-22px)/2;
          left: (56px-31px)/2;
          width: 32px;
          height: 24px;
          background-size: 352% 100% !important;
          background-position: -80px 0;
        }
      }
    }
  }

  .tj-wrap {
    padding-bottom: 24px;
    max-width: 960px;
    .tjlist {
      display: inline-block;
      clear: both;
      zoom: 1;
      li {
        float: left;
        font-size: 16px;
        color: #fff;
        &:not(:last-child) {
          margin-right: 16px;
        }
        a {
          color: #fff;
        }
        &:first-child {
          position: relative;
          margin-right: 33px;
          &:after {
            content: "";
            position: absolute;
            right: -17px;
            top: 7px;
            width: 1px;
            height: 12px;
            background-color: #4f4f4f;
          }
        }
      }
    }
  }
}

/* 辅导课程 辅导资料 筛选条件 年级 科目 */

.lists-filter {
  background-color: #fff;
  color: #5e6061;
  padding: 15px 40px;
  .filter-view {
    position: relative;
    padding: 15px 0 15px 100px;
  }
  .stage {
    &::before {
      content: "请选择年级：";
      position: absolute;
      left: 0;
      top: 20px;
      color: #9a9ea2;
    }
  }
  .subject {
    &::before {
      content: "请选择科目：";
      position: absolute;
      left: 0;
      top: 20px;
      color: #9a9ea2;
    }
  }
  .filter-item {
    display: inline-block;
    padding: 0 16px;
    height: 34px;
    line-height: 34px;
    border-radius: 2px;
    cursor: hand;
    cursor: pointer;
    margin-right: 10px;
    color: #5e6061;
    &:hover {
      color: #1cdcd5;
      background-color: #f6f6f6;
      a {
        color: #1cdcd5;
      }
    }
    &:active {
      opacity: 0.7;
    }
    &.active {
      color: #1cdcd5;
      background-color: #f6f6f6;
      a {
        color: #1cdcd5;
        background-color: #f6f6f6;
      }
    }
  }
}

/* 辅导资料 辅导课程 课程或者资料列表 */
.lists-wrap {
  *zoom: 1;
  &::before{
    display: table;
    line-height: 0;
    content: "";
  }
  &::after{
    display: table;
    line-height: 0;
    content: "";
    clear: both;
  }
  .lists-view{
    float: left;
    width: 928px;
    padding-bottom: 10px;
  }
  .hover-view{
    float: right;
    position: relative;
    z-index:1;
  }
}
.lists {
  width: 928px;
  height: 210px;
  margin-bottom: 24px;
  min-height: 210px;
  .box {
    width: 928px;
    height: 210px;
    background-color: #fff;
  }
  .list-left {
    width: 320px;
    height: 210px;
    margin-right: 24px;
  }
  .list-img {
    width: 320px;
    height: 210px;
  }
  .list-right {
    width: 584px;
    height: 210px;
    padding: 14px 24px 14px 0;
  }
  /* 标题 */
  .infos-title {
    display: block;
    height: 28px;
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition:color 0.2s; 
    &:hover{
        color: #ff814f;
    }
  }
  /* 简介 */
  .infos-info {
    height: 40px;
    font-weight: 400;
    line-height: 20px;
    color: #8b8b8b;
    margin-bottom: 15px;
  }
  /* 标签 */
  .infos-tags {
    margin-bottom: 19px;
  }
  .infos-tags-tag {
    display: inline-block;
    height: 26px;
    padding: 0 8px;
    border: 1px solid #1cdcd5;
    line-height: 24px;
    color: #1cdcd5;
    margin-right: 5px;
    &:last-child {
      margin-right: 0;
    }
    a {
      color: #1cdcd5;
    }
  }
  
}
/* 按钮 */
  .infos-btn {
    display: block;
    text-align: center;
    width: 140px;
    height: 44px;
    line-height: 44px;
    background: #1cdcd5;
    border-radius: 2px;
    font-weight: 400;
    cursor: pointer;
    &:hover {
      color: #fff;
      opacity: 0.7;
    }
  }

/* 右侧 */

.hover-view {
  /* position: absolute;
  top: 0;
  right: 0; */
  width: 260px;
}

.fixed-right {
  position: fixed;
  top: 0;
  right: calc((100% - 1200px) / 2);
  width: 260px;
}

/* 右侧 预约领取试听课 */

.card-baoming {
  width: 260px;
  height: 362px;
  background-color: #fff;
//margin-top: 12px;
  border: 1px solid #F0F0F0;  
}

.baoming-title {
  width: 260px;
  height: 50px;
}

.title-img {
  width: 260px;
  height: 50px;
}

.baoming-content {
  margin-top: 24px;
  .content-title {
    text-align: center;
    height: 20px;
    line-height: 20px;
    font-weight: 800;
    margin-bottom: 16px;
  }
  .main-points {
    display: block;
    position: relative;
    padding-left: 73px;
    height: 20px;
    line-height: 20px;
    color: #434545;
    &:not(:last-child){
        margin-bottom: 8px;
    }
    &::before {
      content: "";
      position: absolute;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: #1cdcd5;
      top: 8px;
      bottom: 7px;
      left: 46px;
    }
  }
}

.baming-input-box {
  margin-top: 24px;
  width: 224px;
  height: 44px;
  padding: 0 18px;
  .baoming-input {
    width: 222px;
    height: 44px;
    line-height: 42px;
    border-radius: 2px;
    border: 1px solid #d9d9d9;
    padding-left: 48px;
    color: #989898;
  }
}

.baoming-btn {
  margin: 12px 18px 0 18px;
  width: 224px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  background-color: #ff814f;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
  &:active {
    opacity: 0.7;
  }
}

/* 右侧 热门活动 */

.card-hotactive {
  display: block;
  width: 260px;
  margin-top: 15px;
  &:first-child{
    margin-top: 28px;
  }
}

.hotactive-title {
  width: 260px;
  height: 25px;
  line-height: 25px;
  position: relative;
  padding-left: 12px;
  &::before {
    content: "";
    position: absolute;
    width: 4px;
    height: 18px;
    background-color: #1cdcd5;
    top: 4px;
    bottom: 3.5px;
    left: 0;
  }
//&::after {
//  content: "查看";
//  position: absolute;
//  height: 18px;
//  line-height: 18px;
//  font-size: 14px;
//  top: 4px;
//  bottom: 3.5px;
//  right: 0;
//  color: #9c9d9e;
//}
}

.hotactive-img {
  display: block;
  margin: 0;
  margin-top: 10px;
  padding: 0;
  width: 100%;
}

.hotactive-info {
    max-height: 57px;
    border: 1px solid #F0F0F0;
    line-height: 22px;
    padding: 7px 10px;
    font-size: 16px;
}

/* 右侧 热门标签 */

.card-hottag {
  display: block;
  width: 260px;
  margin-top: 24px;
}

.hottag-title {
  margin-bottom: 15px;
  width: 260px;
  height: 25px;
  line-height: 25px;
  position: relative;
  padding-left: 12px;
  &::before {
    content: "";
    position: absolute;
    width: 4px;
    height: 18px;
    background-color: #1cdcd5;
    top: 4px;
    bottom: 3.5px;
    left: 0;
  }
}

.tag-li {
  display: inline-block;
    height: 26px;
    line-height: 26px;
    padding: 0 10px;
    border: 1px dashed #6ee7e3;
    margin-bottom: 11px;
    margin-right: 3px;
  &:last-child {
    margin-right: 0;
  }
  .tag-name {
    display: block;
    width: 100%;
    height: 26px;
    line-height: 24px;
    color: #6ee7e3;
    font-size: 14px;
  }
}

/* 列表页和详情页 底部 热门部分 和 友情链接部分 */

.section-second {
  background-color: #fff;
  * {
    letter-spacing: 1px;
  }
  /* 热门相关tabs切换 */
  .card-tabs {
    margin-bottom: 26px;
    .course-hots-ul{
      .tabs-content-li{
        display: block;
        width: 285px;
        margin-right: 20px;
        
        .tabs-content-info{
          width: 285px;
        }
        .course-tit{
          height: 56px;
          line-height: 28px;
        }
        .course-p{
          margin-top: 8px;
          height: 40px;
          line-height: 20px;
          color: #8B8B8B;
        }
      }
    }
  }
  /* tabs header */
  .card-tabs-header {
    margin-top: 36px;
    margin-bottom: 36px;
    .tabs-header-li {
      display: inline-block;
      width: 293px;
      height: 64px;
      line-height: 64px;
      font-weight: 500;
      background-color: #f0f3f6;
      margin-right: 3px;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      &.active {
        color: #fff;
        background: #1cdcd5;
      }
      &:hover {
        color: #fff;
        background: #1cdcd5;
      }
    }
  }
  /* tabs content */
  .card-tabs-content {
    display: none;
    &.active {
      display: block;
    }
    .tabs-content-li {
      display: inline-block;
      width: 285px;
      margin-right: 14.26px;
      &:last-child {
        margin-right: 0;
      }
    }
    .tabs-content-img {
      display: block;
      padding: 0;
      margin: 0;
      width: 100%;
      width: 285px;
      height: 196px;
    }
    .tabs-content-info {
      margin-top: 16px;
      margin-bottom: 12px;
      width: 224px;
      height: 44px;
      font-weight: 500;
      line-height: 22px;
      text-align: justify;
    }
    .tabs-tags-box {
      overflow: hidden;
    }
    .tabs-tag {
      display: inline-block;
      padding: 0 10px;
      height: 26px;
      line-height: 24px;
      border: 1px solid #1cdcd5;
      font-weight: 400;
      color: #1cdcd5;
      margin-right: 3px;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        opacity: 0.7;
        a {
          color: #1cdcd5;
        }
      }
    }
  }
}
/* 友情链接相关tabs切换 */

.card-links {
  margin-bottom: 50px;
  .links-tabs-header {
    display: block;
    position: relative;
    z-index: 1;
    &::before {
      content: "";
      position: absolute;
      width: 1200px;
      height: 2px;
      background-color: #1cdcd5;
      bottom: 0;
      left: 0;
      z-index: 2;
    }
    &::after {
      content: "";
      position: absolute;
      width: 2px;
      height: 56px;
      background-color: #fff;
      top: -2px;
      left: 0;
      z-index: 5;
    }
    .tabs-header-li {
      display: inline-block;
      width: 80px;
      height: 54px;
      line-height: 54px;
      color: #191f25;
      cursor: pointer;
      &.active {
        position: relative;
        z-index: 3;
        color: #1cdcd5;
        &::before {
          content: "";
          position: absolute;
          z-index: 4;
          width: 100%;
          height: 100%;
          bottom: 0;
          left: 0;
          border: 2px solid #1cdcd5;
          border-bottom-color: #fff;
        }
      }
      &:hover {
        color: #1cdcd5;
      }
    }
  }
  .links-tabs-content {
    padding-top: 10px;
    display: none;
    &.active {
      display: block;
    }
    .tabs-content-li {
      width: 200px;
      margin: 8px 0;
      &:not(:nth-child(6n)) {
        a {
          margin-right: 30px;
        }
      }
      a {
        display: block;
        width: 175px;
        font-size: 14px;
        color: #5e6061;
      }
    }
  }
}

/* 详情页面 面包屑 */

.detail-navbar {
  display: block;
  margin: 10px auto;
  height: 20px;
  line-height: 20px;
}

.detail-nav-bar {
  display: inline-block;
  margin: 0 10px;
  color: #616161;
  position: relative;
  &:first-child {
    margin-left: 0;
  }
  &::before {
    content: "";
    position: absolute;
    right: -14px;
    top: 4px;
    width: 1px;
    height: 7px;
    background-color: #616161;
    transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    /* IE 9 */
    -moz-transform: rotate(-45deg);
    /* Firefox */
    -webkit-transform: rotate(-45deg);
    /* Safari 和 Chrome */
    -o-transform: rotate(-45deg);
    /* Opera */
  }
  &::after {
    content: "";
    position: absolute;
    right: -14px;
    top: 9px;
    width: 1px;
    height: 7px;
    background-color: #616161;
    transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    /* IE 9 */
    -moz-transform: rotate(45deg);
    /* Firefox */
    -webkit-transform: rotate(45deg);
    /* Safari 和 Chrome */
    -o-transform: rotate(45deg);
    /* Opera */
  }
  &:last-child {
    &::before {
      width: 0;
      height: 0;
    }
    &::after {
      width: 0;
      height: 0;
    }
    color: #a6a6a6;
    a {
      color: #a6a6a6;
    }
  }
  a {
    color: #616161;
  }
}

/* 详情左侧内容 */

.detail-content {
  width: 889px;
  background-color: #fff;
  padding-bottom: 40px;
  float: left;
  * {
    text-align: justify;
  }
  /* 标题 */
  .detail-htitle {
    font-size: 34px;
    line-height: 48px;
    margin: 0 0 24px 0;
  }
  .detail-params {
    color: #8b8b8b;
    height: 20px;
    line-height: 20px;
    margin-bottom: 50px;
    span {
      margin-right: 24px;
    }
  }
  /* 正文 */
  .detail-main {
    line-height: 30px;
    margin-bottom: 40px;
    color: #333;
    font-size: 16px;
    *{
      letter-spacing: 1.5px
    }
    a{
      color: rgb(24, 104, 223);
    }
    center{
      text-align: center;
    }
    img{
      max-width: 100%!important;
    }
  }
  /* 提示 */
  .detail-tips {
    color: #9d9d9d;
    height: 22px;
    line-height: 22px;
    margin: 0 40px 0 0;
    padding-bottom: 40px;
    box-sizing: content-box;
    border-bottom: 1px solid #e8e8e8;
  }
  /* 文章标签 */
  .detail-tags {
    padding: 40px 40px 24px 0;
    .tags-name {
      color: #5e6061;
      height: 22px;
      line-height: 22px;
    }
    .tags-tag-name {
      height: 22px;
      line-height: 20px;
      border: 1px dashed #1cdcd5;
      padding: 0 5px;
      color: #1cdcd5;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  /* 上一篇，下一篇 */
  .detail-rel {
    padding: 0 40px 0 0;
    margin-bottom: 12px;
    height: 22px;
    line-height: 22px;
  }
}

//关于我们公共 wetong
.datas-wrap {
  .datalist {
    //  padding-top: 85px;
    clear: both;
    zoom: 1;
    display: inline-block;
    width: 100%;
    li {
      width: 226px;
      height: 308px;
      overflow: hidden;
      float: left;
      .limg,
      .limg img {
        width: 226px;
        height: 308px;
      }
      &:not(:nth-child(5n)) {
        margin-right: 17px;
      }
    }
  }
}
.tels {
  font-size: 38px;
  background: url(../images/gradual.png) no-repeat;
  background-size: 100% 100%;
  letter-spacing: 2px;
  text-align: center;
  width: 970px;
  height: 90px;
  line-height: 90px;
  margin: 0 auto;
  margin-bottom: 90px;
}
.block-sub-tit {
  letter-spacing: 2px;
  color: #8b8b8b;
  line-height: 26px;
  padding-top: 24px;
  padding-bottom: 64px;
}
// end

//覆盖分页
.layui-laypage a, .layui-laypage span{
        margin: 0 10px 5px 0 !important;
        width: 65px;
        height: 39px;
        line-height: 39px;
        border:1px solid #CFCFCF !important;
}
.layui-laypage a, .layui-laypage span{
    font-size: 16px;
}

/*footer*/
@media only screen and (max-width: 1024px) {
}

@media only screen and (max-width: 768px) {
}

//动画
.animated {
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both
}

@-webkit-keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 30%, 0);
        transform: translate3d(0, 30%, 0)
    }
    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 30%, 0);
        transform: translate3d(0, 30%, 0)
    }
    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp
}

/* 标签云 */
#tagscloud{
  width:200px;
  height:300px;
  position:relative;
  font-size:12px;
  color:#333;
  margin:-40px auto 0 40px;
  text-align:center;
  a{
    position:absolute;
    top:0px;left:0px;
    color:#333;
    font-family:Arial;
    text-decoration:none;
    margin:0 10px 15px 0;
    line-height:18px;
    text-align:center;
    font-size:12px;
    padding:1px 5px;
    display:inline-block;
    border-radius:3px;
    background:transparent;
    color:#1cdcd5;
    text-align: center;
    width: 100px;
    &:nth-child(2n){
      background:transparent;
      color:#1cdcd5;
    }
    &:nth-child(3n){
      background:transparent;
      color:#1cdcd5;
    }
    &:hover{
      color:#0dece5!important;
    }
    &:focus{
      color:#0dece5!important;
    }
  }
}
// 面包屑样式覆盖
.detail-infos-view{
  border-top: none!important;
}

/* 广告 */
.detail-adver{
  height: 110px;
  overflow: hidden;
  a{
    width: 100%;
    height: 110px;
    display: block;
  }
  img{
    width: 100%;
    height: 110px;
  }
}

/* 公共表单提交成功提示信息弹窗 */
.dialog-form-success{
  position: fixed;
  width: 702px;
  height: 580px;
  border: 7px solid #1CDCD5;
  top: 50%;
  left: 50%;
  margin-top: -290px;
  margin-left: -351px;
  z-index: 99999999;
  background-color: #fff;
  transform: scale(0.8);
  &.dialog-hide{
    display: none;
  }
  .dialog-dashed{
    border: 1px dashed #1CDCD5;
    margin: 15px 17px;
    height: 536px;
    width: 653px;
    position: relative;
    z-index: 10;
  }
  .dialog-title{
    color: #616161;
    padding-top: 33px;
    padding-left: 22px;
  }
  .dialog-infos{
    width: 610px;
    margin: 105px auto;
  }
  .dialog-success-icon-wrap{
    position: relative;
    width: 643px;
    height: 124px;
  }
  .dialog-success-icon{
    width: 94px;
    height: 94px;
    position: absolute;
    top: 20px;
    left: 0;
  }
  .dialog-infos-right{
    width: 490px;
    margin-left: 134px;
  }
  .dialog-message{
    font-size: 40px;
  }
  .dialog-intro{
    letter-spacing: 2px;
    margin-top: 10px;
  }
  .dialog-footer{
    line-height: 28px;
    width: 610px;
    margin: 0 auto;
    text-align: justify;
  }
  .dialog-close{
    position: absolute;
    width: 30px;
    height: 30px;
    top: 22px;
    right: 30px;
    z-index: 11;
    cursor: pointer;
    &::before{
      content: "";
      position: absolute;
      height: 16px;
      width: 1px;
      background-color: #C3C3C3;
      top: 7px;
      bottom: 7px;
      left: 50%;
      right: 50%;
      transform: rotate(-45deg);
    }
    &::after{
      content: "";
      position: absolute;
      height: 16px;
      width: 1px;
      background-color: #C3C3C3;
      top: 7px;
      bottom: 7px;
      left: 50%;
      right: 50%;
      transform: rotate(-135deg);
    }
    &:active{
      background-color: #f2f2f2;
    }
  }
}


// 秦学小q左侧tab
.qinxue-q{
  position: fixed;
  left: 0;
  top:20%;
  width: 100px;
  text-align: center;
  z-index: 999999;
  .qinxue-q-transform{
      animation:heartbeat 1s infinite;
    -webkit-animation:heartbeat 1s infinite;
    width: 68px;
  }
  @keyframes heartbeat {
      0% {
          transform: scale(0.8,0.8);
          opacity:1;
      }
      25% {
          transform: scale(1,1);
          opacity:0.8;
      }
      100% {
          transform: scale(0.8,0.8);
          opacity:1;
      }
  }
  .qinxue-q-title{
      color: #1CDCD5;
      font-size: 18px;
      font-weight: 700;
  }
  .qinxue-q-tree{
      width: 90px;
      //background-color: #ffffff;
      background-color: transparent;
      margin: 0 auto;
      box-shadow: 0 0 20px 0 rgba(0,0,0,0.06);
      overflow: hidden;
      li{
          width: 87px;
          height: 75px;
          text-align: center;
          font-size: 12px;
          color: #191F25;
          line-height: 18px;
          a{
              //padding: 10px 4px;
              //width: 50px;
              //height: 64px;
              //font-size: 12px;
              //color: #191F25;
              //text-overflow: clip;
              //overflow: visible;
              //white-space: normal;
              //line-height: 22px !important;
              //border-bottom:1px solid #E8E8E8;
             height: 80px!important;
          }
          // &:hover{
          //     .qinxue-qinxue-q-tree-li-db-img{
          //          background-image: url(../images/qinxue_q_top_ff.png);
          //     }
          //
          //}

      }

      //.qinxue-qinxue-q-tree-li-db-img{
      //    width: 20px;
      //    height: 10px;
      //    margin: 0 auto;
      //    background-image: url(../images/qinxue_q_top.png);
      //    background-size: 20px 10px;
      //    // &:hover{
      //    //     background-image: url(../images/qinxue_q_top_ff.png);
      //    // }
      //}
    .qinxue-q-tree-li-c1{
      background: url(../images/icon_kecheng_def.png);
      background-size: 86px 94px;
      display: block;
      background-position: center;
      &:hover {
        background: url(../images/icon_kechengfudao_sel.png);
        background-size: 86px 94px;
        display: block;
        background-position: center;
      }
    }
    .qinxue-q-tree-li-c2{
      background: url(../images/icon_zhonggaokao_def.png);
      background-size: 86px 94px;
      display: block;
      background-position: center;
      &:hover {
        background: url(../images/icon_zhonggaokao_sel.png);
        background-size: 86px 94px;
        display: block;
        background-position: center;
      }
    }
    .qinxue-q-tree-li-c3{
      background: url(../images/icon_buxixuexiao_def.png);
      background-size: 86px 94px;
      display: block;
      background-position: center;
      &:hover {
        background: url(../images/icon_buxixuexiao_sel.png);
        background-size: 86px 94px;
        display: block;
        background-position: center;
      }
    }
    .qinxue-q-tree-li-c4{
      background: url(../images/icon_zhiyegaozhong_def.png);
      background-size: 86px 94px;
      display: block;
      background-position: center;
      &:hover {
        background: url(../images/icon_zhiyegaozhong_sel.png);
        background-size: 86px 94px;
        display: block;
        background-position: center;
      }
    }
    .qinxue-q-tree-li-c5{
      background: url(../images/icon_yishuxuexiao_def.png);
      background-size: 86px 94px;
      display: block;
      background-position: center;
      &:hover {
        background: url(../images/icon_yishuxuexiao_sel.png);
        background-size: 86px 94px;
        display: block;
        background-position: center;
      }
    }
    .qinxue-q-tree-li-c6{
      background: url(../images/icon_top_def.png);
      background-size: 86px 94px;
      display: block;
      background-position: center;
      &:hover {
        background: url(../images/icon_top_sel.png);
        background-size: 86px 94px;
        display: block;
        background-position: center;
      }
    }
  }

  .layui-nav-tree .layui-nav-item a:hover{
      background-color: transparent;
      color: #ffffff;
  }

  .layui-nav-tree .layui-this{
      color: #ffffff;
      background-color: transparent;
  }
  .layui-nav-tree .layui-this>a{
      background-color: transparent;
      color: #ffffff;
  }
  .layui-nav-bar{
      width: 0;
      background-color: transparent !important;
  }
}
// 秦学小q弹框
.qinxue-q-tk{
    width: 695px;
    height: 355px;
    border: none;
    position: fixed;
    top: 50%;
    left: 56%;
    margin-top: -177.5px;
    margin-left: -407.5px;
    transform: scale(1);

    .qinxue-q-tk-dialog-dashed{
        margin: -5px;
        width: 709px;
        height: 364px;
        border: none;
    }
    .qinxue-q-tk-conter-loging{
        position: absolute;
        width: 30px;
        height: 30px;
        top: 22px;
        right: 30px;
        z-index: 11;
        cursor: pointer;
    }
    .qinxue-q-tk-conter{
        float: right;
        width: 416px;
        padding: 0 62px;
        height: 347px;
        background-color: #ffffff;
        position: relative;
        top: -355px;
        left: -8px;
        .qinxue-q-tk-conter-title{
            font-size: 28px;
            margin-top: 57px;
            text-align: center;
            color: #191F25;
            .qinxue-q-tk-conter-title-kc{
                color: #FF814F;
            }
        }
        .qinxue-q-tk-conter-cx{
            margin-top: 26px;
            text-align: left;
            color: #B7B7B7;
            font-size: 14px;
        }
        .qinxue-q-tk-conter-inp{
            input {
                font-size: 16px;
                border-radius: 2px;
                width: 293px;
                height: 44px;
                line-height: 44px;
                text-align: center;
                border: 1px solid #D9D9D9;
                margin: 32px 0;
            }
        }
        .qinxue-q-tk-conter-but{
            button{
                    width: 293px;
                    height: 44px;
                    background-color: #FF814F;
                    border: none;
                    border-radius: 2px;
            }
        }
      
    }
}
.J_qform{position: relative;}


.layui-layer-iframe .layui-layer-btn, .layui-layer-page .layui-layer-btn{padding: 20px;}
.layui-layer-btn .layui-layer-btn0{border-color: #1CDCD5 !important;
    background-color: #1CDCD5 !important;}
/*  聊天机器人样式  */
.jesong-container-mini-badge{
  z-index:2;
}

